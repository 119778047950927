export const environment = {
    production: true,
    youtube_key: 'AIzaSyBqHbdg0OZzp7ryaUAuTnbeFQ4_4OoVcOs',
    youtube_api: 'https://www.googleapis.com/youtube/v3/videos',
    name: 'prod',
    apiUrl: 'https://www.lobster-experience.com/',
    messageServiceUrl: 'https://message-service.lobster-experience.com/',
    client_id: '2',
    client_secret: 'vSDyQM08Xi0xg0p2lEMcSIwBI1NyviVoZxXxdyEZ',
    event_domain: 'https://www.lobster-event.com',
    media_url: 'https://www.lobster-experience.com',
    websocket_url: 'wss://socket.lobster-experience.com',
    mapbox: {
        accessToken: 'pk.eyJ1IjoibG9ic3RlcmV4cGVyaWVuY2UiLCJhIjoiY2o1aTJmYWI5MXJsdTJ3czZwY3h0cXJ4YyJ9.-8m5MliR1SPfQDvUe5WVsw',
    },
    version: '1.0.40',
    appApi: 'https://app-service.lobster-experience.com/api/v1',
    buildDate: 'Tue Sep 17 2024 12:36:07 GMT+0000 (Coordinated Universal Time)',
};
